import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../components/Layouts/mainPage"
import { BannerMir } from "../../components/Banners/Mir"
import Advantages from "../../components/Advantages"
import Conditions, { ConditionsItem } from "../../components/Conditions"
import WaysToGetCard from "../../components/WaysToGetCard"
import { MirImg } from "../../components/WaysToGetCard/Images/MirImg"
import Partners from "../../components/Partners"
import Subscription from "../../components/Subscription"
import Discounts from "../../components/Discounts"

import { PageData } from "../../interfaces/pageProps"

import { getPageData } from "../../helpers/getPageData"
import { FormPKW } from "../../components/FormPKW"

const condItems: ConditionsItem[] = [
  {
    img: "icon_deposit",
    title: "До 17%",
    desc: <>на остаток с&nbsp;Подпиской &quot;Халва.Десятка&quot;</>,
  },
  {
    img: "icon_cashback",
    title: "До 10%",
    desc: <>кэшбэк на любые товары с&nbsp;Подпиской &quot;Халва.Десятка&quot;</>,
  },
  {
    img: "icon_cash_withdraw",
    title: "Снятие наличных",
    desc: "в рассрочку",
  },
  {
    img: "icon_bag",
    title: "Более 250 000",
    desc: "магазинов партнеров",
  },
]

const formTitle = "Получить Халву"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, phones, noIndex } = getPageData(data)

  return (
    <Layout ligal={ligal} phones={phones} noIndex={noIndex}>
      <BannerMir orderNum="1" />
      <Advantages orderNum="2" />
      <Subscription orderNum="3" />
      <Discounts orderNum="4" />
      <Conditions head="Невероятные условия" items={condItems} orderNum="5" />
      <WaysToGetCard Img={MirImg} orderNum="6" />
      <FormPKW
        longForm
        dataLayerName="shortPersonalForm"
        title={formTitle}
        longTitle
        additionalEventInDataLayer
        formBtnText="Оформить"
        orderNum="7"
        subTitle="Заберите его в ближайшем офисе Совкомбанка"
        emptyForm={false}
      />
      <Partners orderNum="8" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/pkw/mir/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
    }
  }
`

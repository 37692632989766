// extracted by mini-css-extract-plugin
export var btn = "subscription-module--btn--5mMRr";
export var card = "subscription-module--card--euTLA";
export var cardBody = "subscription-module--cardBody--kYuCq";
export var cardHead = "subscription-module--cardHead--xMj+X";
export var cardHeadRed = "subscription-module--cardHeadRed--CoCaU";
export var head = "subscription-module--head--5w7+6";
export var headline = "subscription-module--headline--Y+xcY";
export var list = "subscription-module--list--BPQa+";
export var listItem = "subscription-module--listItem--f4H4M";
export var listItemBorder = "subscription-module--listItemBorder--tTa+Q";
export var listItemText = "subscription-module--listItemText--u7ySw";
export var moreDetails = "subscription-module--moreDetails--BSUls";
export var moreDetailsText = "subscription-module--moreDetailsText--cB-VZ";
export var root = "subscription-module--root--OnTvM";
export var slide = "subscription-module--slide--MC+Zb";
export var swiper = "subscription-module--swiper--8lAO6";
import React, { useState } from "react"
import cn from "classnames"
import SwiperCore, { SwiperOptions, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Button from "@ecom/ui/components/Button"
import scrollToPersonalForm from "../../helpers/scrollToPersonalForm"

import * as styles from "./subscription.module.scss"

import arrow_down from "./img/arrow_down.svg"
import arrow_up from "./img/arrow_up.svg"

SwiperCore.use([Navigation])

const subscription = [
  "Любые покупки у всех партнёров «Халвы» с единым увеличенным сроком рассрочки 10 месяцев",
  "Кэшбэк 10% за покупку от 50 000 руб. собственными средствами у всех партнеров «Халвы»",
  "Переводы с Халвы до 50 000 руб. БЕЗ комиссии",
  "Защита жизни и здоровья",
  "Поддержка в путешествиях",
]

const free = [
  "Покупки с базовым сроком рассрочки партнёров",
  "Кэшбэк до 6%",
  "Переводы с Халвы комиссия 1%, мин. 50 руб. ",
  <>&mdash;</>,
  <>&mdash;</>,
]

const heightList = [128, 128, 104, 88, 88, 104, 128, 104]

const params: SwiperOptions = {
  slidesPerView: "auto",
  breakpoints: {
    // when window width is >= Xpx
    960: {
      allowTouchMove: false,
    },
  },
}

type SubscriptionProps = {
  orderNum?: string
}

export default function Subscription({ orderNum }: SubscriptionProps) {
  const [open, setOpen] = useState(false)

  const click = () => {
    setOpen(!open)
  }

  return (
    <section className={styles.root} data-exclude={orderNum}>
      <h3 className={cn(styles.head, styles.headline)}>
        Дополнительные преимущества с подпиской “Халва.Десятка”
      </h3>
      <Swiper {...params} className={styles.swiper}>
        <SwiperSlide className={styles.slide}>
          <div className={styles.card}>
            <div className={cn(styles.cardHead, styles.cardHeadRed)}>Подписка «Халва.Десятка»</div>
            <div className={styles.cardBody}>
              <div className={styles.list}>
                {subscription.map((text, index) => {
                  if (open || index < 3) {
                    return (
                      <div
                        key={index}
                        className={styles.listItem}
                        style={{ height: heightList[index] }}
                      >
                        <div className={styles.listItemText}>{text}</div>
                        <div className={styles.listItemBorder} />
                      </div>
                    )
                  }
                  return null
                })}
                <div className={styles.listItem} style={{ height: 128 }}>
                  <div className={styles.listItemText}>
                    0 руб. за 1-й месяц
                    <div style={{ width: "100%", height: 16 }} />
                    399 руб. за 2-й и последующие месяцы
                  </div>
                  <div className={styles.listItemBorder} />
                </div>
              </div>
              <div className={styles.moreDetails}>
                <div aria-hidden="true" className={styles.moreDetailsText} onClick={click}>
                  Подробнее
                </div>
                <div>
                  <img alt="arrow" src={open ? arrow_up : arrow_down} />
                </div>
              </div>
              <Button color="primary" className={styles.btn} onClick={scrollToPersonalForm}>
                Заказать карту
              </Button>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          <div className={styles.card}>
            <div className={styles.cardHead}>Бесплатная Халва</div>
            <div className={styles.cardBody}>
              <div className={styles.list}>
                {free.map((text, index) => {
                  if (open || index < 3) {
                    return (
                      <div
                        key={index}
                        className={styles.listItem}
                        style={{ height: heightList[index] }}
                      >
                        <div className={styles.listItemText}>{text}</div>
                        <div className={styles.listItemBorder} />
                      </div>
                    )
                  }
                  return null
                })}
                <div className={styles.listItem} style={{ height: 128 }}>
                  <div className={styles.listItemText}>
                    0 руб.
                    <div style={{ width: "100%", height: 16 }} /> 0 руб.
                  </div>
                  <div className={styles.listItemBorder} />
                </div>
              </div>
              <div className={styles.moreDetails}>
                <div aria-hidden="true" className={styles.moreDetailsText} onClick={click}>
                  Подробнее
                </div>
                <div>
                  <img alt="arrow" src={open ? arrow_up : arrow_down} />
                </div>
              </div>
              <Button color="primary" className={styles.btn} onClick={scrollToPersonalForm}>
                Заказать карту
              </Button>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}
